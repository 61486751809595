.tableCmp {
	position: relative;
	flex: 1;
	--ag-odd-row-background-color: #f5f7f7;

	:global .ag-root-wrapper {
		border-color: transparent !important;
	}

	:global .ag-header-cell-filtered {
		background: #ff8189;
	}
}

.loadingOverlay {
	display: flex;
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(2px);
	font-size: 2em;
}

.navigationCell {
	background: rgba(255, 255, 0, 0.2);
	cursor: pointer;
}

.centerCell {
	text-align: center;
}

.idCell {
	@extend .navigationCell;
	@extend .centerCell;
}
