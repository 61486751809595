.formLoadingOverlay {
	display: flex;
	position: absolute;
	z-index: 99999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(2px);
	font-size: 2em;
}
