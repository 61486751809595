@import '../shared/variables.scss';

.tabButton {
	display: flex;
	border-right: 1px solid $colour-section-border;
	padding: 0 0.75rem;
	text-decoration: none;
	color: inherit;
	min-width: 5rem;
	font-weight: bold;

	&:hover {
		opacity: 0.8;
	}

	> span {
		margin: auto;
	}
}

.tabButtonSelected {
	background: $colour-section-border;
}
