@import '../../shared/variables.scss';

.screen {
	display: flex;
	flex: 1;
}

.form {
	display: block;
	margin: auto;
	width: 28rem;
	padding: 2rem;
	border: 1px solid $colour-section-border;
	background: $colour-section-bg;
	border-radius: $border-radius-large;
}

.title {
	font-size: 175%;
	font-weight: bold;
}

.table {
	width: 100%;
	table-layout: fixed;
	border: 0;
	border-collapse: collapse;
	margin: 1rem 0;

	> tbody > tr > td {
		padding: 0.5rem;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	> tbody > tr > td:first-child {
		width: 6rem;
	}
}

.errorBox {
	visibility: hidden;
	color: #fd3f4b;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.errorBoxVisible {
	visibility: visible;
}

.button {
	width: 100%;
	height: 2.5rem;
	line-height: 2.5rem;
	font-size: 1.1em;
}
