.loader,
.loader:after {
	border-radius: 50%;
	width: 1em;
	height: 1em;
}

.loader {
	margin: auto;
	font-size: 1em;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	animation: loader-animation 1.1s infinite linear;
}

.light {
	border-top: 1.1em solid rgba(255, 255, 255, 0.2);
	border-right: 1.1em solid rgba(255, 255, 255, 0.2);
	border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
	border-left: 1.1em solid #ffffff;
}

.dark {
	border-top: 1.1em solid rgba(0, 0, 0, 0.2);
	border-right: 1.1em solid rgba(0, 0, 0, 0.2);
	border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
	border-left: 1.1em solid #888;
}

@keyframes loader-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
