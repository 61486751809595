@import './shared/variables.scss';

*,
*::before,
*::after {
	box-sizing: border-box;
	user-select: none;
}

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $colour-screen-bg;
}

#root {
	width: 100%;
	height: 100%;
}

input:not([id^='react-select']),
textarea {
	font-size: 1em;
	padding: 2px 10px;
	min-height: 38px;
	width: 15rem;
	user-select: auto;
	border: 1px solid #cccccc;
	border-radius: 4px;

	&:hover {
		border-color: #b3b3b3;
	}

	&:focus {
		border: 1px solid #2584ff;
		box-shadow: 0 0 0 1px #2584ff;
		outline: 0;
	}
}

textarea {
	padding: 10px;
}
