@import '../../../shared/variables.scss';

.contentItemEditor {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.fieldTitle {
	width: 100% !important;
}

.fieldAuthor {
	max-width: 100% !important;
	width: 25rem !important;
}

.fieldThemes,
.fieldTags {
	flex: 1;
	width: auto;
}

.fieldSummary {
	width: 100%;
	height: 5rem;
	min-height: 5rem;
	resize: vertical;
}

.fieldMetadata {
	width: 100%;
	height: 7.5rem;
	min-height: 5rem;
	resize: vertical;
}

.emptyBodyFormFields {
	color: #bbb;
	font-style: italic;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.formFieldHidden {
	display: none;
}
