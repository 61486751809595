@import '../shared/variables.scss';

.tabContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	border: 1px solid $colour-section-border;
	background: $colour-section-bg;
	border-radius: $border-radius;
	overflow: hidden;

	&:last-child {
		margin-bottom: 0;
	}
}

.tabBar {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	height: 3rem;
	background: #e2e4e7;
	border-bottom: 1px solid $colour-section-border;
	overflow: hidden;
}

.tabBody {
	flex: 1;
	display: flex;
	flex-direction: column;
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
	overflow: hidden;
}
