@import '../../../shared/variables.scss';

.itemsTab {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.errorView {
	display: flex;
	padding: 5rem 2rem;
	color: $colour-error;

	> span {
		margin: auto;
	}
}
