@import '../../shared/variables.scss';

.screen {
	flex: 1;
}

.table {
	width: 100%;
	table-layout: fixed;
	border: 0;
	border-collapse: collapse;

	> tbody > tr > td {
		padding: 0.5rem;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	> tbody > tr > td:first-child {
		width: 10rem;
	}
}

.resultBox {
	visibility: hidden;
	min-height: 1.25rem;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.resultBoxVisible {
	visibility: visible;
}

.resultBoxError {
	color: $colour-error;
}

.resultBoxSuccess {
	color: $colour-success;
}

.button {
}
