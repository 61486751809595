@import '../../../shared/variables.scss';
.screen {
	width: 100%;
}

.sectionContainer {
	display: flex;
	justify-content: flex-start;
	min-width: 10rem;
	flex-wrap: wrap;
}

.report {
	height: 10rem;
	width: 10rem;
	margin: 0.5rem;
	box-shadow: 1px 1px 5px 0px $colour-section-border;
	border-radius: $border-radius-large;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.reportPreview {
		flex: 1;
		padding: 3px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		&.empty {
			background-color: $colour-screen-bg;
			justify-content: flex-end;
			span {
				height: 100%;
				justify-content: flex-end;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
		h5 {
			color: #808080;
			margin: 1rem;
			text-transform: capitalize;
		}
	}

	.reportTitle {
		height: 3rem;
		padding: 3px;
		line-height: 1.3rem;
		box-shadow: 1px 0px 5px -1px $colour-section-border;
		text-align: center;
		font-size: 0.9rem;
		font-weight: bold;
		color: #636363;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

.addCategoryBtn {
	background: transparent;
	color: #636363;
	font-size: 0.8em;
	position: relative;
	top: -25px;
}
