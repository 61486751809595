.overlay {
	z-index: 99;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.modal-main {
	display: flex;
	flex-direction: column;
	flex: 1;
	position: fixed;
	left: 50%;
	top: 50%;
	width: 80%;
	height: 80%;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 10px;
	background: white;
}

.title-bar {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	height: 10%;
	h3 {
		margin: 0;
        color: #66686b;
        width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
	}
}

.content {
	height: 80%;
}

.close-btn {
	position: fixed;
	top: 10px;
	right: 10px;
	margin: 0;
	padding: 0;
	height: 2rem;
	width: 2rem;
	background-color: transparent;
}
