@import '../../shared/variables.scss';

.screen {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: scroll;
}

.errorView {
	display: flex;
	padding: 5rem 2rem;
	color: $colour-error;

	> span {
		margin: auto;
	}
}

.stat-group {
	display: flex;
	flex: 1;
	flex-wrap: wrap;

	@media screen and (max-width: 1250px) {
		flex-direction: column;
	}

	&__cell {
		width: 100%;
		min-width: 0;
		flex: 1;

		&:not(:last-child) {
			margin: 0 3rem 0 0;

			@media screen and (max-width: 1250px) {
				margin: 0;
			}
		}
	}

	canvas {
		background-color: var(--highlight-bg);
		padding: 2rem 1rem;
	}
}

.stat-charts {
	display: flex;
	flex-direction: column;
	margin: 1.5rem 0 0;
}

.sectionContainer {
	margin: 0.75rem 0;
}

.piechartSectionBody {
	text-align: center;
	align-content: center;
	padding: 5%;
}

.tableSectionBody {
	padding: 0;
}

.tableCls {
	height: 15rem;
}
