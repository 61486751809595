@import '../variables.scss';

.button {
	position: relative;
	display: inline-flex;
	height: 2rem;
	line-height: 2rem;
	padding: 0 0.75rem;
	background: $colour-brand-primary;
	border-radius: $border-radius;
	cursor: pointer;
	color: white;
	text-decoration: none;
	text-align: center;
	justify-content: center;

	&:active,
	&visited {
		color: white;
	}

	&:hover {
		opacity: 0.8;
	}
}

.buttonDisabled {
	opacity: 0.3 !important;
	cursor: not-allowed;
	pointer-events: none;
}

.children {
	visibility: hidden;

	> svg {
		margin: 0 0.5rem;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.childrenVisible {
	visibility: visible;
}

.loader {
	position: absolute;
	top: 50%;
	margin-top: -1em;
	visibility: hidden;
}

.loaderVisible {
	visibility: visible;
}
