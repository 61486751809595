@import '../shared/variables.scss';

.section {
	display: block;
	margin: auto;
	margin: 2rem 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.title {
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	color: #66686b;
	font-size: 80%;
	padding: 0 0.5rem;
}

.body {
	padding: 1rem;
	border: 1px solid $colour-section-border;
	background: $colour-section-bg;
	border-radius: $border-radius;
}
