.screen {
	display: flex;
	flex: 1;
}

.errorMsg {
	display: inline-block;
	margin: auto;
	font-size: 1.5em;
}
