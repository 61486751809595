@import '../shared/variables.scss';

.navBar {
	display: flex;
	flex-direction: column;
	background: #262941;
	width: 15rem;
}

.logoSection {
	display: flex;
	height: 5rem;
}

.logoImage {
	display: block;
	width: 80%;
	margin: auto;
}

.navSection {
	flex: 1;
	margin: 1rem 0;
	overflow-y: auto;
}

.navLink {
	display: block;
	height: 2rem;
	line-height: 2rem;
	border-left: 3px solid transparent;
	padding: 0 0.5rem;
	color: white;
	text-decoration: none;

	&:active,
	&:visited {
		color: white;
	}

	&:hover {
		opacity: 0.8;
	}
}

.navLinkActive {
	border-left-color: $colour-brand-primary;
	background: linear-gradient(90deg, #424b69, #262941);
}

.userSection {
	background: #1a1d2d;
	padding: 1.5rem 1rem;
	color: white;
	text-align: center;
}

.userEmail {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-bottom: 1rem;
}

.versionSection {
	display: flex;
	height: 2rem;

	> span {
		display: block;
		margin: auto;
		color: #999;
		font-size: 75%;
	}
}
