@import '../shared/variables.scss';

.app {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.screenContainer {
	display: flex;
	flex: 1;
	margin: 1rem;
}
