@import '../shared/variables.scss';

.toolbar {
	display: flex;
	flex-direction: row;
	height: 3rem;
	background: #f0f2f5;
	border-bottom: 1px solid $colour-section-border;
	align-items: center;
	padding: 0 0.5rem;

	> * {
		margin: 0 1rem;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}
