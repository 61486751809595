@import '../../../shared/variables.scss';
.screen {
    height: 100%;
}

.row {
    display: flex;
    justify-content: space-between;
}

.ag-cell {
    justify-content: center;
 }

.errorMsg {
    display: flex;
	padding: 1rem;
    color: $colour-error;
    > span {
		margin: auto;
	}
}

a {
    text-decoration: none;
    color: #fff;
}
