.formTable {
	width: 100%;
	border: 0;
	border-collapse: collapse;

	> tbody > tr > td {
		padding: 0.5rem;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}
}
