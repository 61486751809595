.fieldFileUpload {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.fileKey {
	margin: 10px;
}

.previewContainer {
	width: 10rem;
	height: 10rem;
	border: 1px solid #ccc;
	background: #eee;
	margin-left: 1rem;
}
