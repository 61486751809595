@import '../../shared/variables.scss';

.fileBrowser {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.content {
	flex: 1;
	display: flex;
	height: calc(100% - 3rem);
}
.previewContainer {
	width: 50%;
	height: 100%;
	border: 1px solid #ccc;
	background: #eee;
	margin-left: 1rem;
}

.errorView {
	display: flex;
	padding: 5rem 2rem;
	color: $colour-error;

	> span {
		margin: auto;
	}
}
