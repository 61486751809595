.highlights {
}

.highlightsSectionBody {
	display: flex;
	flex-direction: row;
	padding-left: 0;
	padding-right: 0;
}

.highlightOrb {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	margin: 0 1rem;
}

.highlightOrbCircle {
	display: flex;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	background-color: grey;
	flex-shrink: 0;

	> span {
		margin: auto;
		font-weight: bold;
	}
}

.highlightOrbLabel {
	padding-left: 0.5rem;
}
