.fieldSelect {
	display: flex;
	align-items: center;
}


.fieldSelectInner {
	width: 15rem;
	margin-right: 0.5rem;
}
