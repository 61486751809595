@import '../variables.scss';

.previewImage {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
}

.previewImageClickable {
	cursor: pointer;
}

.image {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	visibility: hidden;
}

.imageVisible {
	visibility: visible;
}

.imageInstructions {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;

	> span {
		margin: auto;
		padding: 0 1rem;
		font-size: 80%;
	}
}

.imageInstructionsPlain {
	color: #aaa;
}

.imageInstructionsError {
	color: $colour-error;
}

.imageLoader {
	position: absolute;
	top: 50%;
	margin-top: -1em;
	left: 50%;
	margin-left: -1em;
}
