.contentList {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.tabContainer {
	flex: 1;
}

.highlightOrbTotalItems {
}

.highlightOrbDrafts {
}

.highlightOrbThemes {
}

.highlightOrbTotalTags {
}
